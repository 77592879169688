import React from 'react';
import './PeopleInOffice.scss';
import { Trans } from 'react-i18next';

const PeopleInOffice = ({ countUsers, usersAvatar }) => {
  return (
    <div className="people">
      {usersAvatar && usersAvatar.length ? (
        <>
          <div className="people__title">
            <Trans i18nKey="people_in_office" values={{ count: countUsers }} />
          </div>
          <div className="people__avatars avatars">
            <div className="avatars__list">
              {usersAvatar.map((avatar, i) => (
                <div className="avatars__item" key={`avatar${i}`}>
                  {avatar && <img src={avatar} alt="avatar" />}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PeopleInOffice;
