import React, { useContext } from 'react';
import Logo from './Logo';
import FullscreenButton from './FullscreenButton';
import './MainLayout.scss';

const MainLayout = ({ childrenLeft, childrenRight, logoImg, type, bgImage }) => {
  return (
    <div className="container">
      <div className="row">
        <Logo logoImg={logoImg} />
        {childrenLeft}
        <FullscreenButton />
      </div>
      <div
        className="row"
        style={{
          backgroundImage: `${bgImage ? `url(${bgImage})` : `url(${process.env.PUBLIC_URL}/img/${type}-bg.png)`}`,
        }}
      >
        {childrenRight}
      </div>
    </div>
  );
};

export default MainLayout;
