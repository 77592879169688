import React, { useContext, useEffect, useState } from 'react';
import './MeetingRoomPage.scss';
import TodayDateTime from '../components/TodayDateTime';
import WifiInfo from '../components/WifiInfo';
import BookingEvents from '../components/BookingEvents';
import { api } from '../services/http';
import set from 'date-fns/set';
import MainLayout from '../components/MainLayout';
import { generateQRCode } from '../services/generationQrCode';
import { Trans } from 'react-i18next';
import { DeviceAuthContext } from '@inspace-org/react-auth';

const SHOW_BOOKINGS_LENGTH = 4;

const MeetingRoomPage = () => {
  const { device, settings, branding } = useContext(DeviceAuthContext);
  const [space, setSpace] = useState(null);
  const [helpDeskQR, setHelpDeskQR] = useState('');
  const [bookings, setBookings] = useState([]);

  const getBookings = async () => {
    try {
      const { items } = await api.get('/v2/bookings', {
        'dates[0][startTime]': set(new Date(), { hours: 0, minutes: 0, seconds: 0 }).toISOString(),
        'dates[0][endTime]': set(new Date(), { hours: 23, minutes: 59, seconds: 59 }).toISOString(),
        owners: 'any',
        places: device.place,
      });
      const sortResult = sortBookings(items);
      setBookings(sortResult.slice(0, SHOW_BOOKINGS_LENGTH));
    } catch (err) {
      console.error(err);
    }
  };
  const setSpaceById = async (id) => {
    if (!id) {
      return;
    }
    let space = null;
    try {
      const { zones } = await api.get('/v1/zones');
      space = zones.find((zone) => zone._id === id);
    } catch (err) {
      console.error(err);
    }
    return setSpace(space);
  };

  const sortBookings = (bookings) => {
    return bookings
      .sort((a, b) => {
        if (new Date(a.startTime) > new Date(b.startTime)) {
          return 1;
        }
        if (new Date(a.startTime) < new Date(b.startTime)) {
          return -1;
        }
        return 0;
      })
      .filter((el) => new Date(el.endTime) > new Date());
  };

  useEffect(() => {
    setSpaceById(device?.zone);
  }, [device]);

  useEffect(() => {
    const interval = setInterval(() => {
      getBookings();
    }, 180000);
    return () => clearInterval(interval);
  }, [bookings]);

  useEffect(() => {
    if (settings.helpdesk.link) {
      try {
        generateQRCode({
          url: settings.helpdesk.link,
        }).then((res) => {
          setHelpDeskQR(res);
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [settings.helpdesk]);

  useEffect(() => {
    getBookings();
  }, []);

  return (
    <MainLayout
      logoImg={branding.fullLogo}
      bgImage={settings.panel.bgImage}
      type={'meeting_room'}
      childrenLeft={
        <>
          <TodayDateTime darkTheme />
          <BookingEvents bookings={bookings} brandingColor={branding.primaryBgColor} />
        </>
      }
      childrenRight={
        <>
          <div></div>
          <div className="meeting-room">
            <div className="meeting-room__title">
              <Trans i18nKey="meeting_room_title" />
            </div>
            <div className="meeting-room__name">{space?.name}</div>
          </div>
          <div>
            {helpDeskQR && (
              <div className="helpdesk">
                <ul className="helpdesk__list">
                  <li className="helpdesk__item">
                    <div className="helpdesk__qr">
                      <img src={helpDeskQR} alt="helpdesk-qr" />
                    </div>
                  </li>
                  <li className="helpdesk__item">
                    <p>
                      <Trans i18nKey="helpdesk" />
                    </p>
                    <p>
                      <Trans i18nKey="helpdesk_info" />
                    </p>
                  </li>
                </ul>
              </div>
            )}
            {settings.wifi?.ssid && (
              <WifiInfo
                position="left"
                login={settings.wifi.ssid}
                password={settings.wifi.password}
                encryption={settings.wifi.encryption}
                isHidden={settings.wifi.isHidden}
              />
            )}
          </div>
          <div></div>
        </>
      }
    />
  );
};

export default MeetingRoomPage;
