import React from 'react';
import './FullscreenButton.scss';
import { Fullscreen } from '../icons';
import { Trans } from 'react-i18next';

const FullscreenButton = () => {
  const openFullscreen = () => {
    document.documentElement.requestFullscreen();
  };

  return (
    <div className="container fullscreen">
      <button type="button" className="fullscreen__button" onClick={openFullscreen}>
        <p className="fullscreen__text">
          <Trans i18nKey="fullscreen_btn" />
        </p>
        <Fullscreen className="fullscreen__img" />
      </button>
    </div>
  );
};

export default FullscreenButton;
